import React, {useState} from "react";
import "./MintAnnouncement.scss";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import WhoopsiesLogo from "../../assets/WSL2.png";
import { useNavigate } from 'react-router-dom';

export default function MintAnnouncement({ title, content, CTALinks, CTAs, logoVisibility, CTAFunctions }) {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    const handleRedirect = (to) => {
        if (to) {
            window.open(to, '_blank');
        } else {
            navigate(`/${to}`);
        }
    };

    return <>
        {isOpen && (
            <div className="announcementOverlay">
                <div className="announcementContent">
                    <Cross className="cross" onClick={() => {setIsOpen(false)}}/>
                    {logoVisibility && <img src={WhoopsiesLogo} alt="Whoopsies New logo"/>}
                    <h1>{title}</h1>
                    <p>{content}</p>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <button onClick={CTAFunctions[0]}>{CTAs[0]}</button>
                        <button onClick={CTAFunctions[1]}>{CTAs[1]}</button>
                    </div>
                </div>
            </div>
        )}
    </>
}
