import React, { useState, useEffect } from "react";
import { useAccount, useBalance, useContractRead, useContractWrite, useWaitForTransaction } from "wagmi";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import whoopsiesV2Abi from "../../ABI/whoopsiesV2Abi.json";
import { whoopsiesV2Address, rpcURL } from "../../Constant/constants";
import './Mint2Win.scss';
import Navbar from "../../components/NavBar/NavBar";
import Blip from "../../components/Blip/Blip";
import Warning from "../../components/Warning/Warning";
import RaffleLogin from "../../components/RaffleLogin/RaffleLogin";
import MintAnnouncement from "../../components/MintAnnouncement.jsx/MintAnnouncement";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { ReactComponent as LoadingAction } from "../../assets/icons/loadingAction.svg";
import WhoopsiesLogo from '../../assets/WSL2.png';

const CHAIN_ID = 1;

export default function Mint2Win() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [mintCount, setMintCount] = useState(1);
  const [mintRequested, setMintRequested] = useState(false);
  const [mintPrice, setMintPrice] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [mintButtonMessage, setMintButtonMessage] = useState('Mint Whoopsies');
  const [mintedTokenIds, setMintedTokenIds] = useState([]);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [merkleProof, setMerkleProof] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [userMintedCount, setUserMintedCount] = useState(0);
  const [maxMintPerAddress, setMaxMintPerAddress] = useState(0);

  const API_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8888/api' 
    : '/api';

  // Add contract read for mintedPerAddress
  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'mintedPerAddress',
    args: [address],
    chainId: CHAIN_ID,
    enabled: !!address,
    onSuccess(data) {
      setUserMintedCount(Number(data.toString()));
    },
  });

  // Add contract read for maxMintPerAddress
  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'maxMintPerAddress',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setMaxMintPerAddress(Number(data.toString()));
    },
  });

  // Existing contract reads
  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'isSaleActive',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setIsSaleActive(data);
    },
  });

  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'cost',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setMintPrice(ethers.utils.formatEther(data));
    },
  });

  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'maxSupply',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setMaxSupply(data.toString());
    },
  });

  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'totalSupply',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setTotalSupply(data.toString());
    },
  });

  useContractRead({
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: 'discountedCost',
    chainId: CHAIN_ID,
    onSuccess(data) {
      setDiscountedPrice(ethers.utils.formatEther(data));
    },
  });

  useBalance({
    address: address,
    chainId: CHAIN_ID,
    onSuccess(data) {
      setEthBalance(parseFloat(data.formatted));
    }
  });

  useEffect(() => {
    const checkWhitelist = async () => {
      if (!address) return;
  
      try {
        const response = await fetch(`${API_URL}/merkleTreeUtils/merkleProof?address=${address}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setIsWhitelisted(data.isWhitelisted);
        setMerkleProof(data.proof || []);
      } catch (error) {
        console.error("Error checking whitelist:", error);
        setIsWhitelisted(false);
        setMerkleProof([]);
      }
    };
  
    checkWhitelist();
  }, [address]);

  // Updated mint function
  const { data: mintData, write: mint } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: whoopsiesV2Address,
    abi: whoopsiesV2Abi,
    functionName: isWhitelisted ? 'mintWithProof' : 'mint',
    args: isWhitelisted ? [mintCount, merkleProof] : [mintCount],
    value: ethers.utils.parseEther(((isWhitelisted ? discountedPrice : mintPrice) * mintCount).toString()),
    chainId: CHAIN_ID,
    onError(error) {
      toast.error(error.reason?.replace(/execution reverted: /g, "") || "Transaction failed");
      setMintRequested(false);
      setMintButtonMessage('Mint Whoopsies');
    },
    onMutate() {
      const requiredBalance = isWhitelisted ? discountedPrice * mintCount : mintPrice * mintCount;
      if (ethBalance < requiredBalance) {
        toast.error(`Not enough balance, requires ${requiredBalance} ETH to mint!`);
        return;
      }
      
      // Check if minting would exceed user's limit
      if (userMintedCount + mintCount > maxMintPerAddress) {
        toast.error(`You can only mint ${maxMintPerAddress - userMintedCount} more NFTs!`);
        return;
      }
    },
  });

  const { isSuccess: txSuccess } = useWaitForTransaction({
    hash: mintData?.hash,
  });

  useEffect(() => {
    if (txSuccess) {
      toast.success("Minting successful!");
      setMintRequested(false);
      setMintButtonMessage('Minted Successfully!');
      
      const provider = new ethers.providers.JsonRpcProvider(rpcURL);
      const contract = new ethers.Contract(whoopsiesV2Address, whoopsiesV2Abi, provider);
      
      contract.queryFilter(contract.filters.Transfer(null, address))
        .then(events => {
          const tokenIds = events.map(event => event.args.tokenId.toString());
          setMintedTokenIds(tokenIds.slice(-mintCount));
        })
        .catch(error => console.error('Error fetching minted token IDs:', error));
    }
  }, [txSuccess, address, mintCount]);

  const handleMintCountChange = (increment) => {
    setMintCount(prevCount => {
      const newCount = prevCount + increment;
      // Check against remaining allowed mints
      const remainingAllowedMints = maxMintPerAddress - userMintedCount;
      if (newCount <= 0) return prevCount;
      if (newCount > remainingAllowedMints) {
        toast.warn(`You can only mint ${remainingAllowedMints} more NFTs`);
        return prevCount;
      }
      return newCount;
    });
  };

  const handleTweet = () => {
    const tweetText = `I just minted ${mintCount} Whoopsies with ${mintCount} Golden Tickets! Join the biggest raffle yet and spin the wheel of fortune! #Whoopsies #WhoopWhoop #NFT @WDOOPSIES`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, '_blank');
  };

  const handleViewOnEtherscan = () => {
    window.open(`https://etherscan.io/tx/${mintData.hash}`, '_blank');
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      {!address && (
        <div className='loginWrap'>
          <RaffleLogin 
            heading={'Mint New Whoopsies on Ethereum'} 
            tag={'Connect to mint'}
          />
        </div>
      )}

      {(isLoading && address && !isConnecting) && (
        <div className='loadingWrap'>
          <Loading />
          <h1>Loading...</h1>
        </div>
      )}

      <div className='mint2win-page-wrap'>
        {(!isLoading && !isConnecting && address) && (
          <>
            <div className="blip-comp">
              <Blip 
                state={isSaleActive ? 'active' : 'inactive'} 
                about={'Sale '} 
                size={18} 
                alignment={'flex-start'}
              />
            </div>
            <img src={WhoopsiesLogo} alt='Whoopsies Logo' className='logo' />
            <h1>Mint your Whoopsies on Ethereum!</h1>
            
            <div className='mint-portal'>
              <h3>Mint Whoopsies</h3>
              <p>Select how many Whoopsies you want to mint!</p>
              <p>You have minted {userMintedCount} out of {maxMintPerAddress} allowed NFTs</p>
              <div className='mint-counter'>
                <button onClick={() => handleMintCountChange(-1)}>-</button>
                <span>{mintCount}</span>
                <button onClick={() => handleMintCountChange(1)}>+</button>
              </div>
              <p>Price: {((isWhitelisted ? discountedPrice : mintPrice) * mintCount).toFixed(3)} ETH</p>
              {isWhitelisted && <p>You are whitelisted for a discount!</p>}
              <p>Total Supply: {totalSupply} / {maxSupply}</p>
              {mintCount > 10 && (
                <Warning statement={'Its recommended to mint fewer than 10 NFTs at once.'}/>
              )}
              <button 
                className={`mint-button ${mintRequested || !isSaleActive || userMintedCount >= maxMintPerAddress ? 'button-disabled' : ''}`}
                disabled={mintRequested || !isSaleActive || userMintedCount >= maxMintPerAddress}
                onClick={() => {
                  setMintRequested(true);
                  mint();
                }}
              >
                {userMintedCount >= maxMintPerAddress ? 'Max Mints Reached' : mintButtonMessage}
                <LoadingAction style={{ display: mintRequested ? 'block' : 'none' }}/>
              </button>
            </div>
          </>
        )}

        {(txSuccess && mintedTokenIds.length > 0) && (
          <MintAnnouncement
            title={`Congratulations! 🎉 You've Minted ${mintedTokenIds.length} Whoopsies!`}
            content={`Join our Discord to get free spins on the Wheel of Fortune and win guaranteed prizes! Share with friends for extra raffle entries.`}
            CTAFunctions={[handleViewOnEtherscan, handleTweet]}
            CTAs={['View Transaction', 'Share to win Extra!']}
            logoVisibility={false}
          />
        )}
      </div>
    </>
  );
}