import React from "react"

import styles from './styles.module.scss';
import About from "../../components/About/About";
import VideoSection from "../../components/videoSection/VideoSection";
import RoadMap from "../../components/RoadMap/RoadMap";
import Team from "../../components/Team/Team";
import Gallery from "../../components/Gallery/Gallery";
import NavBar from "../../components/NavBar/NavBar";
import AboutDashBoard from "../../components/AboutDashboard/AboutDashBoard";
import Announcement from "../../components/Announcement/Announcement";

export default function Home() {
  return (
    <>
     <NavBar />
        <div style={{overflowX: "hidden"}}>
            <Announcement 
              title={'Mint is Now Live!'} 
              content={'Every mint grants you free spins on our Wheel of Fortune and automatically enters you into our biggest giveaway yet: a Bored Ape Yacht Club NFT upon collection mint-out! Plus, each mint comes with a Golden Ticket !\n\nReady to join? Hit the Mint Now button and start collecting!'} 
              CTALink={'Mint2Win'} 
              CTA={'Mint Now'} 
              logoVisibility={true}
            />
            <VideoSection />
            <AboutDashBoard />
            <About />
            <RoadMap />
            <Team />
        </div>
        {/*
        <Gallery />
        */}
      <div className={styles.homeContainer}>
      </div>
    </>
  )
}
