import React, { useState } from "react";
import "./Announcement.scss";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import WhoopsiesLogo from "../../assets/WSL2.png";
import { useNavigate } from 'react-router-dom';

export default function Announcement({ 
    title, 
    content, 
    CTALink, 
    CTA, 
    logoVisibility, 
    CTAFunction 
}) {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    
    const handleRedirect = (to) => {
        navigate(`/${to}`);
    };

    const handleCTA = () => {
        if (CTALink) {
            handleRedirect(CTALink);
        } else if (CTAFunction) {
            CTAFunction();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="announcementOverlay">
            <div className="announcementContent">
                <Cross 
                    className="cross" 
                    onClick={() => setIsOpen(false)}
                    aria-label="Close announcement"
                />
                {logoVisibility && (
                    <img 
                        src={WhoopsiesLogo} 
                        alt="Whoopsies Logo"
                    />
                )}
                <h1>{title}</h1>
                <p>{content}</p>
                <button 
                    onClick={handleCTA}
                    type="button"
                >
                    {CTA}
                </button>
            </div>
        </div>
    );
}