import React from "react";
import styles from "./styles.module.scss";

// Import images
import alex from "../../assets/avatars/alex.png";
import boston from "../../assets/avatars/boston.jpg";
import phill from "../../assets/avatars/phill.png";
import likemyrpg from "../../assets/avatars/likemyrpg.png";

const SocialIcon = ({ linktype }) => {
  switch (linktype) {
    case 'twitter':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
        </svg>
      );
    case 'telegram':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
        </svg>
      );
    case 'website':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
        </svg>
      );
    default:
      return null;
  }
};

const team = [
  {
    avatar: alex,
    name: "Alex",
    bio: "Founder / IT project manager from ExxonMobil. In crypto since 2014 . Building friendships and setting up collaborations in space ever since. Houston, TX",
    link: "https://twitter.com/otherguydoteth",
    linktype: "twitter"
  },
  {
    avatar: boston,
    name: "Mr Boston",
    bio: "COO of @WDoopsies🥶, Husband and father of 2. Airbnb Superhost, Coach, & Real Estate Investor.",
    link: "https://twitter.com/bostonbeerguy_",
    linktype: "twitter"
  },
  {
    avatar: phill,
    name: "Phill",
    bio: "Phill (AKA GrizzlyDesign) is a freelance Web3 developer that created this website/dashboard and has worked with many large projects in the crypto space including TCG World, $GREED, DRIVENx, and many more. ",
    link: "https://t.me/grizzlydesign",
    linktype: "telegram"
  },
  {
    avatar: likemyrpg,
    name: "likemyRPG",
    bio: "LikemyRPG is a freelance full stack developer working on various projects in the crypto space.",
    link: "https://likemyrpg.dev",
    linktype: "website"
  }
];

export default function Team() {
  return (
    <div className={styles.teamSection}>
      <div id="team" className={styles.titleContainer}>
        <h2>MEET THE CREW</h2>
      </div>
      <div className={styles.teamContainer} style={{ display: 'flex', justifyContent: 'center' }}>
        {team.map((member) => (
          <div key={member.name} className={styles.teamCard}>
            <div className={styles.cardInner}>
              <div className={styles.avatarContainer}>
                <img alt="team member avatar" src={member.avatar} />
              </div>
              <div className={styles.avatarContainerBack}>
                <span className={styles.nameContainer}>
                  <h2>{member.name}</h2>
                  <a
                    href={member.link}
                    className={styles.socialIcon}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialIcon linktype={member.linktype} />
                  </a>
                </span>
                <p>{member.bio}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}